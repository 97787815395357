import {useEffect} from 'react'

function usePageTitle({
  title,
  includeDefaultTitle = true,
}: {
  title: string
  includeDefaultTitle?: boolean
}) {
  useEffect(() => {
    document.title = !includeDefaultTitle ? `${title}` : `${title} | Loving Memory`
  }, [includeDefaultTitle, title])
}

export default usePageTitle

import axios, {AxiosResponse} from 'axios'

import {Order, OrderType, OrderTypeTemplate, OrdersQueryResponse} from './_models'
import {IAvailableStep} from '../../../../../hooks/useOrderDetailWebsocket'

const API_URL = process.env.REACT_APP_API_URL

export const queryForOrders = (query: string): Promise<OrdersQueryResponse> => {
  return axios
    .get(`${`${API_URL}/orders/query/`}?${query}`)
    .then((d: AxiosResponse<OrdersQueryResponse>) => d.data)
    .then((d: OrdersQueryResponse) => {
      d.data = (d.data || []).map((x: any) => new Order(x))
      return d
    })
}

export const getOrderTypeTemplates = (): Promise<Array<OrderTypeTemplate>> => {
  return axios
    .get(`${API_URL}/orders/order-type-templates/`)
    .then((d: AxiosResponse<any>) => {
      return d.data
    })
    .then((d: any) => {
      return d.data.map((x: any) => new OrderTypeTemplate(x))
    })
}

export const createDraftOrder = (data: any) => {
  return axios
    .post(`${API_URL}/orders/create-draft/`, data)
    .then((d: AxiosResponse<any>) => d.data)
    .then((d: any) => {
      return new Order(d.data.order)
    })
}

export const getOrderByUniqueId = (uniqueId: string) => {
  return axios
    .get(`${API_URL}/orders/${uniqueId}/`)
    .then((d: AxiosResponse<any>) => d.data)
    .then((d: any) => {
      return new Order(d.data.order)
    })
}

export const getOrderStep = (orderUniqueId: string, stepType: IAvailableStep) => {
  return axios
    .get(`${API_URL}/orders/${orderUniqueId}/steps/${stepType}/`)
    .then((d: AxiosResponse<any>) => d.data)
    .then((d: any) => {
      return d.data
    })
}

import {useCallback, useEffect, useState} from 'react'
import {Order} from '../modules/apps/orders/orders-list/core/_models'
import {getOrderStep} from '../modules/apps/orders/orders-list/core/_requests'
import {ApiError} from '../modules/auth'
import {IAvailableStep} from './useOrderDetailWebsocket'

function useOrderDetailPartialLoader({
  order,
  stepType,
  onLoaded,
}: {
  order: Order
  stepType: IAvailableStep
  onLoaded: (order: Order) => void
}) {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorLoading, setErrorLoading] = useState<string | null>(null)

  const loadOrder = useCallback(async () => {
    if (!order.unique_id) {
      // We don't have an error here because this could be used on a new order
      setLoading(false)
      return
    }
    setLoading(true)
    try {
      const updatedOrder = await getOrderStep(order.unique_id, stepType)
      onLoaded(updatedOrder)
      setLoading(false)
    } catch (error: any) {
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to load order'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.unique_id])

  useEffect(() => {
    loadOrder()
  }, [loadOrder])

  return {loading, errorLoading}
}

export default useOrderDetailPartialLoader

import {Response} from '../../../../../../_metronic/helpers'

// order_type_template:

export class OrderTypeTemplate {
  unique_id: string
  name: string
  description: string
  // Commenting out until we fix TypeScript errors
  // slug: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.name = data.name
    this.description = data.description
    // this.slug = data.slug
  }
}

export class OrderType {
  unique_id: string
  inserted_at: string
  updated_at: string
  order_type_template: OrderTypeTemplate

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.order_type_template = new OrderTypeTemplate(data.order_type_template)
  }
}

export class OrderStatus {
  name: string
  slug: string

  constructor(data: any) {
    this.name = data.name
    this.slug = data.slug
  }
}

export class PetInformation {
  pet_name: string
  type_of_pet_value: 'cat' | 'dog' | 'other' | null
  type_of_pet_other: string
  breed_selection_option: any
  breed_selection_other: string
  other_pet_type_description: string
  gender_value: 'male' | 'female' | null
  birth_date: string
  death_date: string
  weight_value: string
  weight_unit: 'pounds' | 'kilograms' | null

  constructor(data: any) {
    this.pet_name = data.pet_name
    this.type_of_pet_value = data.type_of_pet_value
    this.type_of_pet_other = data.type_of_pet_other
    this.breed_selection_option = data.breed_selection_option
    this.breed_selection_other = data.breed_selection_other
    this.other_pet_type_description = data.other_pet_type_description
    this.gender_value = data.gender_value
    this.birth_date = data.birth_date
    this.death_date = data.death_date
    this.weight_value = data.weight_value
    this.weight_unit = data.weight_unit
  }

  getSimpleName() {
    return this.pet_name
  }

  getSingleLineDescription() {
    // Should this be in the API?
    // Make type_of_pet_value capitalized (only first letter)
    const petType = this.type_of_pet_value
      ? this.type_of_pet_value[0].toUpperCase() + this.type_of_pet_value.slice(1)
      : ''
    // Sorry for the world's worst ternary that follows:
    return `${petType}${
      this.breed_selection_option
        ? ` (${
            this.breed_selection_option.label || this.breed_selection_option.label || 'Unknown'
          })`
        : this.breed_selection_other
        ? ` (${this.breed_selection_other})`
        : this.other_pet_type_description
        ? ` (${this.other_pet_type_description})`
        : ''
    }`.trim()
  }
}

export class OwnerInformation {
  owner_first_name: string
  owner_last_name: string

  owner_email_address: string
  owner_phone_number: string

  google_place_address: any

  manual_address?: {
    owner_address_line_1: string
    owner_address_line_2: string
    owner_city: string
    owner_state_value: string
    owner_zip_code: string
  }

  constructor(data: any) {
    this.owner_first_name = data.owner_first_name
    this.owner_last_name = data.owner_last_name
    this.owner_email_address = data.owner_email_address
    this.owner_phone_number = data.owner_phone_number
    this.google_place_address = data.google_place_address
    this.manual_address = data.manual_address
      ? {
          owner_address_line_1: data.manual_address.owner_address_line_1,
          owner_address_line_2: data.manual_address.owner_address_line_2,
          owner_city: data.manual_address.owner_city,
          owner_state_value: data.manual_address.owner_state_value,
          owner_zip_code: data.manual_address.owner_zip_code,
        }
      : undefined
  }

  getSimpleName() {
    // Should this be in the API?
    if (!this.owner_first_name && !this.owner_last_name) {
      return ''
    }
    return `${this.owner_first_name || ''} ${this.owner_last_name || ''}`.trim()
  }

  getSingleLineDescription() {
    const components = []
    if (this.owner_email_address) {
      components.push(this.owner_email_address)
    }
    if (this.owner_phone_number) {
      components.push(this.owner_phone_number)
    }
    return components.join(' | ')
  }
}

export class Order {
  unique_id: string
  inserted_at: string
  updated_at: string

  order_type: OrderType
  order_status: OrderStatus

  picture_url?: string

  pet_information?: PetInformation
  owner_information?: OwnerInformation

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at

    this.order_type = new OrderType(data.order_type)
    this.order_status = new OrderStatus(data.order_status)

    this.picture_url = data.picture_url

    this.pet_information = data.pet_information
      ? new PetInformation(data.pet_information)
      : undefined

    this.owner_information = data.owner_information
      ? new OwnerInformation(data.owner_information)
      : undefined
  }
}

export type OrdersQueryResponse = Response<Array<Order>>

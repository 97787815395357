import {FC, useCallback, useEffect, useState} from 'react'
import clsx from 'clsx'

import OrderBottomActions from '../components/OrderBottomActions'
import {ApiError} from '../../../auth'
import {getOrderTypeTemplates, createDraftOrder} from '../orders-list/core/_requests'
import {Order, OrderType, OrderTypeTemplate, PetInformation} from '../orders-list/core/_models'
import {useNavigate} from 'react-router-dom'
import {useCurrentOrder} from './OrderDetail'
import useOrderDetailPartialLoader from '../../../../hooks/useOrderDetailPartialLoader'
import useWebsocketMessage from '../../../../hooks/useWebsocketMessage'

function SelectableOrder({
  orderType,
  selected,
  onSelected,
}: {
  orderType: OrderTypeTemplate
  selected: boolean
  onSelected: () => void
}) {
  return (
    <div
      className={clsx(
        'd-flex align-items-center bg-light-danger rounded p-5 mb-7 text-hover-primary cursor-pointer',
        selected ? 'border-primary border border-2' : 'border-gray-300 border border-2'
      )}
      onClick={onSelected}
    >
      <div className='flex-grow-1 me-2'>
        <div className='fw-bold text-gray-800 fs-6'>{orderType.name}</div>
        <span className='text-muted fw-semibold d-block'>{orderType.description}</span>
      </div>
    </div>
  )
}

const StepOrderType = () => {
  const navigate = useNavigate()

  const {
    order,
    connectedWebsocketClientId,
    connectedWebsocket,
    sendInputChanged,
    sendObjectChanged,
  } = useCurrentOrder()

  const [availableOrderTypes, setAvailableOrderTypes] = useState<OrderTypeTemplate[]>([])

  // cremationOptions1 is every odd index, cremationOptions2 is every even index

  const availableOrderTypesColumn1 = availableOrderTypes
    .filter((_, index) => index % 2 === 0)
    .map((orderType) => orderType)

  const availableOrderTypesColumn2 = availableOrderTypes
    .filter((_, index) => index % 2 === 1)
    .map((orderType) => orderType)

  const [selectedOption, setSelectedOption] = useState<string | null>(
    order && order.unique_id && order.order_type && order.order_type.order_type_template
      ? order.order_type.order_type_template.unique_id
      : null
  )

  const [creatingDraftOrder, setCreatingDraftOrder] = useState(false)
  const [errorCreatingDraftOrder, setErrorCreatingDraftOrder] = useState<string | null>(null)

  const [loadingOrderTypes, setLoadingOrderTypes] = useState(false)
  const [errorLoadingOrderTypes, setErrorLoadingOrderTypes] = useState<string | null>(null)

  const loadOrderTypes = useCallback(async () => {
    setLoadingOrderTypes(true)
    try {
      const updatedOrderTypeTemplates = await getOrderTypeTemplates()
      setAvailableOrderTypes(updatedOrderTypeTemplates)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to load order types'
      setErrorLoadingOrderTypes(errorMessage)
    } finally {
      setLoadingOrderTypes(false)
    }
  }, [])

  const onCreateDraftOrder = useCallback(async () => {
    if (!selectedOption) {
      return
    }
    setCreatingDraftOrder(true)
    setErrorCreatingDraftOrder(null)
    try {
      const order = await createDraftOrder({
        orderTypeUniqueId: selectedOption,
      })
      navigate(`/orders/view/${order.unique_id}/pet-information`)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to create order'
      setErrorCreatingDraftOrder(errorMessage)
      setCreatingDraftOrder(false)
    }
  }, [navigate, selectedOption])

  const onContinue = useCallback(() => {
    if (!order.unique_id) {
      onCreateDraftOrder()
    } else {
      navigate(`/orders/view/${order.unique_id}/pet-information`)
    }
  }, [navigate, onCreateDraftOrder, order.unique_id])

  useEffect(() => {
    loadOrderTypes()
  }, [loadOrderTypes])

  const {loading: partialLoading, errorLoading: partialErrorLoading} = useOrderDetailPartialLoader({
    order,
    stepType: 'order_type',
    onLoaded: (data: any) => {
      const updatedOrderType = new OrderType(data)
      setSelectedOption(updatedOrderType.order_type_template.unique_id)
    },
  })

  useWebsocketMessage({
    connectedWebsocket,
    connectedWebsocketClientId,
    onReceivedStepMessage: (parsedMessage) => {
      if (parsedMessage.step_type === 'order_type') {
        if (parsedMessage.name === 'order_type_template_unique_id') {
          setSelectedOption(parsedMessage.value)
        } else {
          console.warn('Unknown `order_type` name: ', parsedMessage.name)
        }
      }
    },
  })

  const onSelectedOrderTypeChanged = useCallback(
    (orderType: OrderTypeTemplate) => {
      setSelectedOption(orderType.unique_id)
      if (order.unique_id) {
        sendInputChanged({
          step: 'order_type',
          name: 'order_type_template_unique_id',
          value: orderType.unique_id,
        })
      }
    },
    [order.unique_id, sendInputChanged]
  )

  if (loadingOrderTypes || partialLoading) {
    return <div>Loading...</div>
  }

  if (errorLoadingOrderTypes || partialErrorLoading) {
    return <div className='alert alert-danger'>{errorLoadingOrderTypes || partialErrorLoading}</div>
  }

  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Clinic Submission
            {false && (
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Billing is issued based on your selected account type'
              ></i>
            )}
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
            The order will be submitted and managed by the clinic on behalf of the owner.
          </div>
        </div>

        <div className='fv-row'>
          <div className='row'>
            <div className='row mb-3 mb-xl-3'>
              <div className='col-8 offset-2'>
                <select
                  className='form-select form-select-lg mb-3'
                  aria-label='.form-select-lg example'
                  defaultValue='1'
                >
                  <option>Select a location</option>
                  <option value='1'>Caring Pathways - Corporate Office</option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='mb-5 mb-xl-8 pt-8'>
                  <div>
                    {availableOrderTypesColumn1.map((orderType) => {
                      return (
                        <SelectableOrder
                          key={orderType.unique_id}
                          orderType={orderType}
                          selected={selectedOption === orderType.unique_id}
                          onSelected={() => onSelectedOrderTypeChanged(orderType)}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='mb-5 mb-xl-8 pt-8'>
                  <div>
                    {availableOrderTypesColumn2.map((orderType) => {
                      return (
                        <SelectableOrder
                          key={orderType.unique_id}
                          orderType={orderType}
                          selected={selectedOption === orderType.unique_id}
                          onSelected={() => onSelectedOrderTypeChanged(orderType)}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderBottomActions
        disabled={!selectedOption || creatingDraftOrder}
        onContinue={onContinue}
        continueLabel={!creatingDraftOrder ? 'Continue' : 'Creating...'}
        error={errorCreatingDraftOrder}
        hideBack
      />
    </>
  )
}

export default StepOrderType

export * from './core/_models'
export * from './core/Auth'
export * from './core/AuthHelpers'
export * from './AuthPage'
export * from './Logout'

export class ApiError {
  message: string
  response: any

  constructor(message: string, response: any) {
    this.message = message
    this.response = response
  }
}

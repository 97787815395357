import BiographyForm from './BiographyForm'

function StepPetInformation() {
  return (
    <div className='w-100'>
      <div className='pb-3'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Pet Information
          {false && (
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          )}
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>Enter the pet's information below</div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <BiographyForm addressedToUser={false} />
        </div>
      </div>
    </div>
  )
}

export default StepPetInformation

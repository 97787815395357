import {FC, createContext, useCallback, useContext, useEffect, useState} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation, useParams} from 'react-router-dom'
import ReconnectingWebSocket from 'reconnecting-websocket'

import {PageTitle} from '../../../../../_metronic/layout/core'

import OrderSteps from './OrderSteps'

import {Order} from '../orders-list/core/_models'
import {getOrderByUniqueId} from '../orders-list/core/_requests'
import {ApiError} from '../../../auth'
import StepPetInformation from './StepPetInformation'
import StepOwnerInformation from './StepOwnerInformation'
import useOrderDetailWebsocket, {IAvailableStep} from '../../../../hooks/useOrderDetailWebsocket'
import StepOrderType from './StepOrderType'

interface OrderDetailContextProps {
  order: Order
  loadOrder: () => void
  connectedWebsocketClientId: string | null
  connectedWebsocket: ReconnectingWebSocket | null
  sendPageView: (page: IAvailableStep) => void
  sendInputFocused: ({name, step}: {name: string; step: IAvailableStep}) => void
  sendInputBlurred: ({name, step}: {name: string; step: IAvailableStep}) => void
  sendCheckboxChanged: ({
    name,
    value,
    step,
  }: {
    step: IAvailableStep
    name: string
    value: boolean
  }) => void
  sendInputChanged: ({
    step,
    name,
    value,
  }: {
    step: IAvailableStep
    name: string
    value: string
  }) => void
  sendObjectChanged: ({step, name, value}: {step: IAvailableStep; name: string; value: any}) => void
}

const OrderDetailContext = createContext<OrderDetailContextProps>({
  order: {} as Order,
  loadOrder: () => {},
  connectedWebsocketClientId: null,
  connectedWebsocket: null,
  sendPageView: () => {},
  sendInputFocused: () => {},
  sendInputBlurred: () => {},
  sendCheckboxChanged: () => {},
  sendInputChanged: () => {},
  sendObjectChanged: () => {},
})

export const useCurrentOrder = () => useContext(OrderDetailContext)

const OrderDetail = () => {
  const {order} = useCurrentOrder()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='order-type' element={<StepOrderType />} />
        <Route path='pet-information' element={<StepPetInformation />} />
        <Route path='owner-information' element={<StepOwnerInformation />} />
        <Route path='memorial-products' element={<>Memorial Products</>} />
        <Route path='review' element={<>Review</>} />
        <Route
          path='*'
          element={<Navigate to={`/orders/view/${order.unique_id}/order-type`} replace />}
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/orders/view/${order.unique_id}/order-type`} replace />}
      />
    </Routes>
  )
}

const OrderDetailWrapper: FC = () => {
  const {uniqueId} = useParams()
  const {pathname} = useLocation()

  const [loading, setLoading] = useState<boolean>(true)
  const [order, setOrder] = useState<Order | undefined>(undefined)
  const [errorLoading, setErrorLoading] = useState<string | null>(null)

  const {
    connectedWebsocketClientId,
    connectedWebsocket,
    sendPageView,
    sendInputFocused,
    sendInputBlurred,
    sendCheckboxChanged,
    sendInputChanged,
    sendObjectChanged,
  } = useOrderDetailWebsocket(order)

  const loadOrder = useCallback(async () => {
    if (!uniqueId) {
      console.warn('No uniqueId provided')
      setErrorLoading('No uniqueId provided')
      setLoading(false)
      return
    }
    setLoading(true)
    try {
      const order = await getOrderByUniqueId(uniqueId)
      setOrder(order)
      setLoading(false)
    } catch (error: any) {
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to load order'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [uniqueId])

  useEffect(() => {
    loadOrder()
  }, [loadOrder])

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {errorLoading ? (
            <>
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{errorLoading}</div>
              </div>
            </>
          ) : (
            <>
              {!order ? (
                <>
                  <div className='alert alert-danger' role='alert'>
                    <div className='alert-text'>Order not found</div>
                  </div>
                </>
              ) : (
                <OrderDetailContext.Provider
                  value={{
                    order,
                    loadOrder,
                    connectedWebsocketClientId: connectedWebsocketClientId.current,
                    connectedWebsocket,
                    sendPageView,
                    sendInputFocused,
                    sendInputBlurred,
                    sendCheckboxChanged,
                    sendInputChanged,
                    sendObjectChanged,
                  }}
                >
                  <PageTitle
                    breadcrumbs={[]}
                    description={`${order.order_type.order_type_template.name} - Order ${order.unique_id}`}
                  >
                    View Order
                  </PageTitle>
                  <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
                    <OrderSteps />
                    <div className='d-flex flex-row-fluid bg-body rounded'>
                      <div className='py-10 w-100 px-3 px-lg-6 px-xxl-10'>
                        <OrderDetail />
                      </div>
                    </div>
                  </div>
                </OrderDetailContext.Provider>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default OrderDetailWrapper

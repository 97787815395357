import {useState} from 'react'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const GOOGLE_PLACES_API_KEY = 'AIzaSyD0oAGfnQSBR5aHMMBRuFS5UEznMhxoIOY'

export interface GooglePlaceSelectOption {
  label: string
  value: {
    place_id: string
    description: string
  }
}

function OwnerAddressAutocomplete({
  selectedAddress,
  setSelectedAddress,
}: {
  selectedAddress: GooglePlaceSelectOption | null
  setSelectedAddress: (address: GooglePlaceSelectOption) => void
}) {
  return (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_PLACES_API_KEY}
      autocompletionRequest={{
        types: [
          // https://developers.google.com/places/web-service/autocomplete#place_types
          'address',
        ],
      }}
      selectProps={{
        placeholder: 'Search for an address...',
        onChange: (value: any) => {
          setSelectedAddress(value)
        },
        value: selectedAddress,
        autoFocus: false,
        isClearable: true,
        styles: {
          control: (provided: any) => ({
            ...provided,
          }),
          input: (provided: any) => ({
            ...provided,
          }),
          option: (provided: any) => ({
            ...provided,
          }),
        },
      }}
    />
  )
}

export default OwnerAddressAutocomplete

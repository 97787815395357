import {useState} from 'react'
import Select, {createFilter} from 'react-select'

import catBreeds from '../../../../../data/catBreeds'
import dogBreeds, {BaseDogBreed} from '../../../../../data/dogBreeds'

function BreedSelector({
  typeOfBreed,
  selectedBreed,
  setSelectedBreed,
  required = undefined,
}: {
  typeOfBreed: 'cat' | 'dog' | null
  selectedBreed: {
    label: string
    value: string
  } | null
  setSelectedBreed: (
    selectedBreed: {
      label: string
      value: string
    } | null
  ) => void
  required?: boolean
}) {
  const isDisabled = typeOfBreed === null
  return (
    <Select
      className='react-select-dark-container'
      classNamePrefix='react-select-dark'
      name={`relationship`}
      placeholder={
        !isDisabled ? 'Select or search for a breed...' : 'Select the type of pet first...'
      }
      options={
        typeOfBreed === 'dog'
          ? dogBreeds.map((breed: BaseDogBreed, index) => {
              return {
                value: breed.name,
                label: breed.name,
              }
            })
          : catBreeds.map((breed, index) => {
              return {
                value: breed.name,
                label: breed.name,
              }
            })
      }
      onChange={(value: any) => {
        if (value) {
          setSelectedBreed(value)
        } else {
          setSelectedBreed(null)
        }
      }}
      required={required}
      value={selectedBreed}
      isClearable
      formatOptionLabel={(option: any) => {
        return option.label
      }}
      filterOption={createFilter({
        ignoreAccents: true,
        ignoreCase: true,
        matchFrom: 'any',
        stringify: (option: any) => {
          return option.data?.searchableLabel || option.label
        },
      })}
      isDisabled={isDisabled}
    />
  )
}

export default BreedSelector

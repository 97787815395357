import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

import {KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'

type NewOrderCardProps = {}

const NewOrderCard: FC<NewOrderCardProps> = () => {
  const useSmallCard = useMediaQuery({query: '(max-width: 1370px)'})

  return (
    <Link
      to='/orders/submit'
      className='card border border-2 border-gray-300 border-hover'
      style={{
        height: useSmallCard ? 100 : 450,
        marginBottom: useSmallCard ? '2.5rem' : 0,
      }}
    >
      {/* Add a centered plus sign with text */}
      <div
        className='d-flex justify-content-center align-items-center'
        style={{height: useSmallCard ? 100 : 450}}
      >
        <div className='d-flex flex-column align-items-center'>
          <KTSVG
            path='/media/icons/duotune/arrows/arr009.svg'
            className={clsx('svg-icon-primary', !useSmallCard ? 'svg-icon-4x' : 'svg-icon-2x')}
          />
          <div className='fs-3 fw-bolder text-dark mt-5'>New Order</div>
        </div>
      </div>
    </Link>
  )
}

export default NewOrderCard

import {FC} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'

import StepOrderType from '../order-detail/StepOrderType'
import usePageTitle from '../../../../hooks/usePageTitle'

const OrdersSubmitWrapper: FC = () => {
  usePageTitle({
    title: 'Submit an Order',
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Submit an Order</PageTitle>
      <div>
        <div className='d-flex flex-row-fluid flex-center bg-body rounded shadow'>
          <div>
            <div className='py-10 w-100 px-3 px-lg-6 px-xxl-10'>
              <StepOrderType />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrdersSubmitWrapper

export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export class UserProfile {
  first_name?: string
  last_name?: string
  name?: string
  name_initials?: string

  hasGeneratedHumanName?: boolean
  humanName?: string
  humanNameTitle?: string
  humanNameFirst?: string
  humanNameMiddle?: string
  humanNameLast?: string
  humanNameSuffix?: string
  humanNameNickname?: string

  profile_picture_url?: string

  constructor(data: any) {
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.name = data.name
    this.name_initials = data.name_initials
    this.hasGeneratedHumanName = data.has_generated_human_name
    this.humanName = data.human_name
    this.humanNameTitle = data.human_name_title
    this.humanNameFirst = data.human_name_first
    this.humanNameMiddle = data.human_name_middle
    this.humanNameLast = data.human_name_last
    this.humanNameSuffix = data.human_name_suffix
    this.humanNameNickname = data.human_name_nickname
    this.profile_picture_url = data.profile_picture_url
  }

  getSimpleName() {
    // This order is important.
    // "Human name" is parsing we do on a full name to guess at the name's structure.
    // We do this to make the input simpler.
    // We default to human name if parsed,
    // first name if available, and finally
    // just the original name.
    if (this.humanNameFirst) {
      return this.humanNameFirst
    }
    if (this.humanNameNickname) {
      return this.humanNameNickname
    }
    if (this.first_name) {
      return this.first_name
    }
    return this.name
  }
}

export class UserModel {
  unique_id: string
  avatar_url: string
  date_created: string
  date_last_active: string
  email: string
  email_is_unknown: boolean // We auto-generate system emails if signing up with a phone number

  // Name has been moved into the user profile

  phone_number?: string
  phone_number_verified: boolean

  client_admin_memberships: any[]
  // active_funeral_home_memberships: FuneralHomeMembership[]

  // onboarding_flags: OnboardingFlags

  has_active_subscription: boolean
  is_admin: boolean
  is_superuser: boolean

  user_profile: UserProfile

  current_funeral_home_unique_id?: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.avatar_url = data.avatar_url
    this.date_created = data.date_created
    this.date_last_active = data.date_last_active
    this.email = data.email
    this.email_is_unknown = data.email_is_unknown
    this.client_admin_memberships = [] // TODO: data.client_admin_memberships
    this.phone_number = data.phone_number
    this.phone_number_verified = data.phone_number_verified
    // this.onboarding_flags = new OnboardingFlags(data.onboarding_flags)
    this.has_active_subscription = data.has_active_subscription
    /*
    this.active_funeral_home_memberships = data.active_funeral_home_memberships.map(
      (membership: any) => new FuneralHomeMembership(membership)
    )
    */
    this.is_admin = data.is_admin
    this.is_superuser = data.is_superuser
    this.user_profile = new UserProfile(data.user_profile)
    this.setupAxiosInterceptors()
  }

  /*
  isFuneralHomeUser() {
    return this.onboarding_flags.selected_user_type !== 'other'
  }

  isWaitingForApproval() {
    return this.active_funeral_home_memberships.filter((x) => x.funeral_home.is_active).length === 0
  }

  hasSelectedUserType() {
    return this.onboarding_flags.has_selected_user_type
  }

  hasFuneralHomeMembership() {
    return this.active_funeral_home_memberships.length > 0
  }

  setCurrentFuneralHomeId(funeralHomeUniqueId: string | undefined) {
    this.current_funeral_home_unique_id = funeralHomeUniqueId
  }

  */

  setupAxiosInterceptors() {
    /*
    const returnUser = this // To make sure it always works inside the interceptor

    let defaultFuneralHomeId = undefined

    if (returnUser.active_funeral_home_memberships.length > 0) {
      defaultFuneralHomeId = localStorage.getItem(LOCAL_STORAGE_SELECTED_FUNERAL_HOME) || ''

      // make sure that currentUser?.active_funeral_home_memberships it contains the defaultTenantId
      // if not, set the defaultTenantId to the first tenant in the list
      // this is important in case the user has been removed from a tenant

      // admins can access all funeral homes, so we don't need to check if they have access to the default one
      if (
        !returnUser.active_funeral_home_memberships
          .map((membership) => membership.funeral_home.unique_id)
          .includes(defaultFuneralHomeId)
      ) {
        defaultFuneralHomeId =
          returnUser.active_funeral_home_memberships[0].funeral_home.unique_id || ''
      }
    }
    returnUser.setCurrentFuneralHomeId(defaultFuneralHomeId)

    axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (returnUser.current_funeral_home_unique_id) {
          config.headers = {
            ...config.headers,
            'x-funeral-home-unique-id': returnUser.current_funeral_home_unique_id,
          }
        }
        return config
      }
    )
    */
  }
}

export class SimpleUserModel {
  unique_id: string
  email: string
  email_is_unknown: boolean
  inserted_at: string
  avatar_url: string
  phone_number: string
  phone_number_verified: boolean
  last_active_at: string
  // onboarding_flags: OnboardingFlags
  has_active_subscription: boolean
  is_admin: boolean
  user_profile: UserProfile

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.email = data.email
    this.email_is_unknown = data.email_is_unknown
    this.inserted_at = data.inserted_at
    this.avatar_url = data.avatar_url
    this.phone_number = data.phone_number
    this.phone_number_verified = data.phone_number_verified
    this.last_active_at = data.last_active_at
    // this.onboarding_flags = new OnboardingFlags(data.onboarding_flags)
    this.has_active_subscription = data.has_active_subscription
    this.is_admin = data.is_admin
    this.user_profile = new UserProfile(data.user_profile)
  }
}

import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import OrdersSubmitWrapper from '../modules/apps/orders/orders-submit/OrdersSubmitWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const CremationOperatorsPage = lazy(
    () => import('../modules/apps/cremation-operators/CremationOperatorsPage')
  )
  const VetClinicsPage = lazy(() => import('../modules/apps/vet-clinics/ClinicsPage'))
  const OrdersPage = lazy(() => import('../modules/apps/orders/OrdersPage'))
  const DeliveriesPage = lazy(() => import('../modules/apps/deliveries/DeliveriesPage'))
  const BillingWrapper = lazy(() => import('../pages/billing/BillingWrapper'))
  const InvoicesWrapper = lazy(() => import('../pages/invoices/InvoicesWrapper'))

  const PricingWrapper = lazy(() => import('../pages/pricing/PricingWrapper'))

  const ResourcesPage = lazy(() => import('../modules/apps/resources/ResourcesPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after successful login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='cremation-operators/*'
          element={
            <SuspensedView>
              <CremationOperatorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='vet-clinics/*'
          element={
            <SuspensedView>
              <VetClinicsPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='orders/submit/*' element={<OrdersSubmitWrapper />} />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='deliveries/*'
          element={
            <SuspensedView>
              <DeliveriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='billing/*'
          element={
            <SuspensedView>
              <BillingWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='invoices/*'
          element={
            <SuspensedView>
              <InvoicesWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='pricing/*'
          element={
            <SuspensedView>
              <PricingWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='resources/*'
          element={
            <SuspensedView>
              <ResourcesPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard?404' replace />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

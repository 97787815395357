import {KTIcon} from '../../../../../_metronic/helpers'

function OrderBottomActions({
  disabled = false,
  onBack,
  onContinue,
  continueLabel = 'Continue',
  error = undefined,
  hideBack = false,
}: {
  disabled?: boolean
  onBack?: () => void
  onContinue: () => void
  continueLabel?: string
  error?: string | null | undefined
  hideBack?: boolean
}) {
  return (
    <>
      <div className='separator separator-dashed my-10'></div>

      <div className='d-flex flex-stack align-items-center justify-content-center'>
        {!hideBack && (
          <div className='mr-2'>
            <button
              onClick={() => {
                if (onBack) {
                  onBack()
                }
              }}
              type='button'
              className='btn btn-lg btn-light-primary me-3'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1' />
              Back
            </button>
          </div>
        )}
        <div>
          <button
            type='button'
            className='btn btn-lg btn-primary me-3'
            disabled={disabled}
            onClick={onContinue}
          >
            <span className='indicator-label'>
              {continueLabel}
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </span>
          </button>
        </div>
      </div>

      {error && (
        <div className='d-flex flex-stack align-items-center justify-content-center mt-3'>
          <div className='text-danger'>{error}</div>
        </div>
      )}
    </>
  )
}

export default OrderBottomActions

import {useCallback, useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'
import {DateTime} from 'luxon'
import {ButtonGroup, Button, Col, Row} from 'react-bootstrap'
import {useMediaQuery} from 'react-responsive'

import BreedSelector from './BreedSelector'
import GenderSelector from './GenderSelector'
import PetTypeSelector from './PetTypeSelector'
import getDateAsAstring from '../../../../../_metronic/helpers/getDateAsString'
import OrderBottomActions from '../components/OrderBottomActions'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useCurrentOrder} from './OrderDetail'
import useWebsocketMessage from '../../../../hooks/useWebsocketMessage'
import useOrderDetailPartialLoader from '../../../../hooks/useOrderDetailPartialLoader'
import {PetInformation} from '../orders-list/core/_models'

function BiographyForm({addressedToUser = false}: {addressedToUser?: boolean}) {
  const {
    order,
    connectedWebsocketClientId,
    connectedWebsocket,
    sendInputChanged,
    sendObjectChanged,
  } = useCurrentOrder()

  const navigate = useNavigate()

  const isMobileDevice = useMediaQuery({query: '(max-width: 700px)'})

  const [petName, setPetName] = useState<string>(order.pet_information?.pet_name || '')

  const [enteringTypeOfBreedManually, setEnteringTypeOfBreedManually] = useState(
    order.pet_information?.breed_selection_other ? true : false
  )

  const [typeOfBreed, setTypeOfBreed] = useState<'cat' | 'dog' | 'other' | null>(
    order.pet_information?.type_of_pet_value || null
  )

  const [breedSelectionOther, setBreedSelectionOther] = useState<string>(
    order.pet_information?.breed_selection_other || ''
  )

  const [otherPetTypeDescription, setOtherPetTypeDescription] = useState<string>(
    order.pet_information?.other_pet_type_description || ''
  )

  const [selectedBreed, setSelectedBreed] = useState<{
    label: string
    value: string
  } | null>(
    order.pet_information?.breed_selection_option &&
      order.pet_information?.breed_selection_option.value &&
      order.pet_information?.breed_selection_option.label
      ? {
          value: order.pet_information?.breed_selection_option.value,
          label: order.pet_information?.breed_selection_option.label,
        }
      : null
  )

  const [typeOfGender, setTypeOfGender] = useState<'male' | 'female' | null>(
    order.pet_information?.gender_value || null
  )

  const [selectedBirthDate, setSelectedBirthDate] = useState<string | null>(
    order.pet_information?.birth_date ? order.pet_information?.birth_date : null
  )
  const [selectedDeathDate, setSelectedDeathDate] = useState<string | null>(
    order.pet_information?.death_date ? order.pet_information?.death_date : null
  )

  const [weight, setWeight] = useState<number | null>(
    order.pet_information?.weight_value ? parseInt(order.pet_information?.weight_value, 10) : null
  )

  const [weightVariant, setWeightVariant] = useState<'pounds' | 'kilograms'>(
    order.pet_information?.weight_unit || 'pounds'
  )

  const [gotPetAtBirth, setGotPetAtBirth] = useState<boolean | null>(null)

  const onFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }, [])

  const onReset = useCallback(() => {
    setSelectedBreed(null)
    /*
    // For now we only reset the selected breed.
    setTypeOfGender(null)
    setSelectedBirthDate(null)
    setWeight(null)
    setGotPetAtBirth(null)
    */
  }, [])

  const {loading: partialLoading, errorLoading: partialErrorLoading} = useOrderDetailPartialLoader({
    order,
    stepType: 'pet_information',
    onLoaded: (data: any) => {
      // TODO: Use formik...
      const updatedPetInformation = new PetInformation(data)
      // Or make the following initialization a helper function.
      setPetName(updatedPetInformation.pet_name || '')
      setEnteringTypeOfBreedManually(updatedPetInformation.breed_selection_other ? true : false)
      setTypeOfBreed(updatedPetInformation.type_of_pet_value || null)
      setBreedSelectionOther(updatedPetInformation.breed_selection_other || '')
      setOtherPetTypeDescription(updatedPetInformation.other_pet_type_description || '')
      setSelectedBreed(
        updatedPetInformation.breed_selection_option &&
          updatedPetInformation.breed_selection_option.value &&
          updatedPetInformation.breed_selection_option.label
          ? {
              value: updatedPetInformation.breed_selection_option.value,
              label: updatedPetInformation.breed_selection_option.label,
            }
          : null
      )
      setTypeOfGender(order.pet_information?.gender_value || null)
      setSelectedBirthDate(order.pet_information?.birth_date || null)
      setSelectedDeathDate(order.pet_information?.death_date || null)
      setWeight(
        order.pet_information?.weight_value
          ? parseInt(order.pet_information?.weight_value, 10)
          : null
      )
      setWeightVariant(order.pet_information?.weight_unit || 'pounds')
    },
  })

  useWebsocketMessage({
    connectedWebsocket,
    connectedWebsocketClientId,
    onReceivedStepMessage: (parsedMessage) => {
      if (parsedMessage.step_type === 'pet_information') {
        // TODO: Use this hook if we are on another step also somehow (update the order state?)
        // Otherwise we can retrieve a partial when we jump between steps.
        if (parsedMessage.name === 'pet_name') {
          setPetName(parsedMessage.value)
        } else if (parsedMessage.name === 'type_of_pet_value') {
          setTypeOfBreed(
            parsedMessage.value ? (parsedMessage.value as 'cat' | 'dog' | 'other') : null
          )
        } else if (parsedMessage.name === 'breed_selection_option') {
          setSelectedBreed(
            parsedMessage.value && parsedMessage.value.value && parsedMessage.value.label
              ? {
                  value: parsedMessage.value.value,
                  label: parsedMessage.value.label,
                }
              : null
          )
        } else if (parsedMessage.name === 'breed_selection_other') {
          setBreedSelectionOther(parsedMessage.value)
        } else if (parsedMessage.name === 'other_pet_type_description') {
          setOtherPetTypeDescription(parsedMessage.value)
        } else if (parsedMessage.name === 'gender_value') {
          setTypeOfGender(parsedMessage.value ? (parsedMessage.value as 'male' | 'female') : null)
        } else if (parsedMessage.name === 'birth_date') {
          setSelectedBirthDate(parsedMessage.value)
        } else if (parsedMessage.name === 'death_date') {
          setSelectedDeathDate(parsedMessage.value)
        } else if (parsedMessage.name === 'weight_value') {
          setWeight(parsedMessage.value ? parseInt(parsedMessage.value, 10) : null)
        } else if (parsedMessage.name === 'weight_unit') {
          setWeightVariant(
            parsedMessage.value ? (parsedMessage.value as 'pounds' | 'kilograms') : 'pounds'
          )
        } else {
          console.warn('Unknown `pet_information` name: ', parsedMessage.name)
        }
      }
    },
  })

  return (
    <form onSubmit={onFormSubmit}>
      <div className='form-group'>
        <div className='d-flex mb-2 flex-column'>
          <label className='form-label'>
            {addressedToUser ? `Your pet's name:` : `Pet's name:`}
          </label>
          <input
            className='form-control'
            type='text'
            name='name'
            placeholder={addressedToUser ? `Enter your pet's name...` : `Enter pet's name...`}
            required
            autoComplete='off'
            autoFocus={!petName}
            value={petName}
            onChange={(event) => {
              const updatedValue = event.target.value
              setPetName(updatedValue)
              sendInputChanged({
                step: 'pet_information',
                name: 'pet_name',
                value: updatedValue,
              })
            }}
          />
        </div>
        <div className='mt-4'>
          <label className='form-label'>
            {addressedToUser ? `What is your pet's type?` : 'Type of pet:'}
          </label>
          <PetTypeSelector
            typeOfBreed={typeOfBreed}
            setTypeOfBreed={(updatedTypeOfBreed) => {
              setTypeOfBreed(updatedTypeOfBreed)
              onReset()
              sendInputChanged({
                step: 'pet_information',
                name: 'type_of_pet_value',
                value: updatedTypeOfBreed || '',
              })
            }}
          />
        </div>
        {typeOfBreed !== 'other' ? (
          <div className='mt-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <label className='form-label m-0 p-0'>
                {addressedToUser ? `Your pet's breed:` : `Pet's breed:`}
              </label>
              <button
                type='button'
                className='btn btn-link btn-sm'
                onClick={() => {
                  setEnteringTypeOfBreedManually(!enteringTypeOfBreedManually)
                  setBreedSelectionOther('')
                  setSelectedBreed(null)
                  sendObjectChanged({
                    step: 'pet_information',
                    name: 'breed_selection_option',
                    value: null,
                  })
                  sendInputChanged({
                    step: 'pet_information',
                    name: 'breed_selection_other',
                    value: '',
                  })
                }}
              >
                {enteringTypeOfBreedManually ? `Find a breed` : `Enter a breed manually`}
              </button>
            </div>
            {enteringTypeOfBreedManually ? (
              <>
                <div>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Enter a breed...'
                    value={breedSelectionOther}
                    onChange={(event) => {
                      const updatedValue = event.target.value
                      setBreedSelectionOther(updatedValue)
                      sendInputChanged({
                        step: 'pet_information',
                        name: 'breed_selection_other',
                        value: updatedValue,
                      })
                    }}
                  />
                </div>
              </>
            ) : (
              <BreedSelector
                typeOfBreed={typeOfBreed}
                selectedBreed={selectedBreed}
                setSelectedBreed={(updatedSelectedBreed) => {
                  setSelectedBreed(
                    updatedSelectedBreed
                      ? {
                          label: updatedSelectedBreed.label,
                          value: updatedSelectedBreed.value,
                        }
                      : null
                  )
                  sendObjectChanged({
                    step: 'pet_information',
                    name: 'breed_selection_option',
                    value: updatedSelectedBreed,
                  })
                }}
              />
            )}
          </div>
        ) : (
          <>
            <div className='mt-4'>
              <label className='form-label'>
                {addressedToUser ? `Describe your pet:` : `Describe the type of pet:`}
              </label>
              <input
                className='form-control'
                type='text'
                placeholder='Describe the pet...'
                value={otherPetTypeDescription}
                onChange={(event) => {
                  const updatedValue = event.target.value
                  setOtherPetTypeDescription(updatedValue)
                  sendInputChanged({
                    step: 'pet_information',
                    name: 'other_pet_type_description',
                    value: updatedValue,
                  })
                }}
              />
            </div>
          </>
        )}
        <div className='mt-4'>
          <label className='form-label'>
            {addressedToUser ? `What is your pet's gender?` : `Pet's gender:`}
          </label>
          <GenderSelector
            typeOfGender={typeOfGender}
            setTypeOfGender={(updatedTypeOfGender) => {
              setTypeOfGender(updatedTypeOfGender)
              sendInputChanged({
                step: 'pet_information',
                name: 'gender_value',
                value: updatedTypeOfGender || '',
              })
            }}
          />
        </div>
        <div className='row mt-4'>
          <div className='col-6 col-xxl-3'>
            <label className='form-label'>
              {addressedToUser
                ? `Your pet's birth date (if known):`
                : `Pet's birth date (if known):`}
            </label>
            <Row>
              <Col xs={12} md={12}>
                <DatePicker
                  className='form-control'
                  selected={
                    selectedBirthDate ? DateTime.fromISO(selectedBirthDate).toJSDate() : null
                  }
                  onChange={(date: any) => {
                    const updatedFieldValue = date ? getDateAsAstring(date) : ''
                    setSelectedBirthDate(updatedFieldValue)
                    sendInputChanged({
                      step: 'pet_information',
                      name: 'birth_date',
                      value: updatedFieldValue || '',
                    })
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  dateFormat={[
                    'MMMM do, yyyy', // May 1, 2021
                    'MMMM do yyyy', // May 1 2021
                    'MMMM d, yyyy', // May 1, 2021
                    'P', // 2021-05-01
                    'PP', // 05/01/2021
                    'PPP', // May 1, 2021
                    'MMM dd yyyy', // May 01 2021
                    'MMMM dd yyyy', // May 01 2021
                    'MMMM d yyyy', // May 1 2021
                    // Also allow 01-01-2021 and 01/01/2021 and 01.01.2021 and 01 01 2021 and 01-01-2021
                    // and 01/01/21 and 01/01/21 and 01.01.21 and 01 01 21 and 01-01-21
                    'MM-dd-yyyy',
                    'MM/dd/yyyy',
                    'MM.dd.yyyy',
                    'MM dd yyyy',
                    'MM-dd-yy',
                    'MM/dd/yy',
                    'MM.dd.yy',
                    'MM dd yy',
                  ]}
                  showYearDropdown
                  showMonthDropdown
                  placeholderText='Select date...'
                  required={false}
                  name={`birthDate`}
                  autoComplete='off'
                  dropdownMode='select'
                  disabled={false}
                />
              </Col>
            </Row>
          </div>
          <div className='col-6 col-xxl-3'>
            <label className='form-label'>
              {addressedToUser
                ? `Your pet's passing date (if known):`
                : `Pet's passing date (if known):`}
            </label>
            <Row>
              <Col xs={12} md={12}>
                <DatePicker
                  className='form-control'
                  selected={
                    selectedDeathDate ? DateTime.fromISO(selectedDeathDate).toJSDate() : null
                  }
                  onChange={(date: any) => {
                    const updatedFieldValue = date ? getDateAsAstring(date) : ''
                    setSelectedDeathDate(updatedFieldValue)
                    sendInputChanged({
                      step: 'pet_information',
                      name: 'death_date',
                      value: updatedFieldValue || '',
                    })
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  dateFormat={[
                    'MMMM do, yyyy', // May 1, 2021
                    'MMMM do yyyy', // May 1 2021
                    'MMMM d, yyyy', // May 1, 2021
                    'P', // 2021-05-01
                    'PP', // 05/01/2021
                    'PPP', // May 1, 2021
                    'MMM dd yyyy', // May 01 2021
                    'MMMM dd yyyy', // May 01 2021
                    'MMMM d yyyy', // May 1 2021
                    // Also allow 01-01-2021 and 01/01/2021 and 01.01.2021 and 01 01 2021 and 01-01-2021
                    // and 01/01/21 and 01/01/21 and 01.01.21 and 01 01 21 and 01-01-21
                    'MM-dd-yyyy',
                    'MM/dd/yyyy',
                    'MM.dd.yyyy',
                    'MM dd yyyy',
                    'MM-dd-yy',
                    'MM/dd/yy',
                    'MM.dd.yy',
                    'MM dd yy',
                  ]}
                  showYearDropdown
                  showMonthDropdown
                  placeholderText='Select date...'
                  required={false}
                  name={`birthDate`}
                  autoComplete='off'
                  dropdownMode='select'
                  disabled={false}
                />
              </Col>
            </Row>
          </div>
          <div className='col-12 col-xxl-6 mt-xxl-0 mt-4'>
            <label className='form-label'>
              {addressedToUser ? `Your pet's weight:` : `Pet's weight:`}
            </label>
            <Row>
              <Col xs={6}>
                <input
                  className='form-control'
                  type='number'
                  name='weight'
                  placeholder={
                    addressedToUser
                      ? !isMobileDevice
                        ? `Enter your pet's weight...`
                        : `Weight...`
                      : `Enter pet's weight...`
                  }
                  autoComplete='off'
                  value={weight || ''}
                  onChange={(e) => {
                    const updatedFieldValue = e.target.value ? parseInt(e.target.value) : null
                    setWeight(updatedFieldValue)
                    sendInputChanged({
                      step: 'pet_information',
                      name: 'weight_value',
                      value: updatedFieldValue ? `${updatedFieldValue}` : '',
                    })
                  }}
                />
              </Col>
              <Col xs={6}>
                <ButtonGroup className='d-flex'>
                  <button
                    type='button'
                    className={clsx(
                      'btn',
                      weightVariant === 'pounds' ? 'btn-primary' : 'btn-light-primary'
                    )}
                    onClick={() => {
                      if (weightVariant === 'kilograms' && weight !== null) {
                        const updatedWeight = Math.round(weight * 2.20462)
                        setWeight(updatedWeight)
                        sendInputChanged({
                          step: 'pet_information',
                          name: 'weight_value',
                          value: updatedWeight ? `${updatedWeight}` : '',
                        })
                      }
                      setWeightVariant('pounds')
                      sendInputChanged({
                        step: 'pet_information',
                        name: 'weight_unit',
                        value: 'pounds',
                      })
                    }}
                  >
                    {!isMobileDevice ? 'Pounds' : 'lbs'}
                  </button>
                  <button
                    type='button'
                    className={clsx(
                      'btn',
                      weightVariant === 'kilograms' ? 'btn-primary' : 'btn-light-primary'
                    )}
                    onClick={() => {
                      if (weightVariant === 'pounds' && weight !== null) {
                        const updatedWeight = Math.round(weight / 2.20462)
                        setWeight(updatedWeight)
                        sendInputChanged({
                          step: 'pet_information',
                          name: 'weight_value',
                          value: updatedWeight ? `${updatedWeight}` : '',
                        })
                      }
                      setWeightVariant('kilograms')
                      sendInputChanged({
                        step: 'pet_information',
                        name: 'weight_unit',
                        value: 'kilograms',
                      })
                    }}
                  >
                    {!isMobileDevice ? 'Kilograms' : 'kgs'}
                  </button>
                </ButtonGroup>
              </Col>
            </Row>
          </div>
        </div>
        {addressedToUser && (
          <>
            {typeOfBreed === 'cat' || typeOfBreed === 'dog' ? (
              <div className='mt-4'>
                <label className='form-label'>
                  {typeOfBreed === 'dog'
                    ? `Did you get your dog as a puppy, or did you adopt them later in life?`
                    : `Did you get your cat as a kitten, or did you adopt them later in life?`}
                </label>
                <div>
                  <ButtonGroup className='d-flex'>
                    <Button
                      variant={gotPetAtBirth === true ? 'light' : 'outline-light'}
                      onClick={() => {
                        setGotPetAtBirth(true)
                      }}
                    >
                      {typeOfBreed === 'dog' ? 'Puppy' : 'Kitten'}
                    </Button>
                    <Button
                      variant={gotPetAtBirth === false ? 'light' : 'outline-light'}
                      onClick={() => {
                        setGotPetAtBirth(false)
                      }}
                    >
                      {typeOfBreed === 'cat' ? 'Adopted' : 'Adopted'}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ) : null}
          </>
        )}
        <OrderBottomActions
          hideBack={false}
          onBack={() => {
            navigate(`/orders/view/${order.unique_id}/order-type`)
          }}
          onContinue={() => {
            navigate(`/orders/view/${order.unique_id}/owner-information`)
          }}
        />
      </div>
    </form>
  )
}

export default BiographyForm

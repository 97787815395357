import {useEffect} from 'react'
import {
  handleIncomingWebsocketMessage,
  OrderStepActionInputChangedUpdate,
} from './useOrderDetailWebsocket'
import ReconnectingWebSocket from 'reconnecting-websocket'

function useWebsocketMessage({
  connectedWebsocket,
  connectedWebsocketClientId,
  onReceivedStepMessage,
}: {
  connectedWebsocket: ReconnectingWebSocket | null
  connectedWebsocketClientId: string | null
  onReceivedStepMessage: (message: OrderStepActionInputChangedUpdate) => void
}) {
  useEffect(() => {
    if (connectedWebsocket) {
      const handleMessage = (message: any) => {
        // First we parse the message. We use handleIncomingWebsocketMessage:
        const parsedMessage = handleIncomingWebsocketMessage(message)
        if (parsedMessage instanceof OrderStepActionInputChangedUpdate) {
          if (parsedMessage.socket_identifier !== connectedWebsocketClientId) {
            onReceivedStepMessage(parsedMessage)
          }
        }
      }
      connectedWebsocket.addEventListener('message', handleMessage)
      return () => {
        if (connectedWebsocket) {
          connectedWebsocket.removeEventListener('message', handleMessage)
        }
      }
    }
  }, [connectedWebsocket, connectedWebsocketClientId, onReceivedStepMessage])
}

export default useWebsocketMessage

export interface BaseCatBreed {
  name: string;
  url?: string;
  isCfa?: boolean;
}

const cfaBreeds = require("./cfa_breeds.json") as BaseCatBreed[];

// Combine the array and sort by name

const catBreeds = [
  ...cfaBreeds.map((x) => {
    x.isCfa = true;
    return x;
  }),
].sort((a, b) => a.name.localeCompare(b.name));

export default catBreeds;

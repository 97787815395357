import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useCurrentOrder} from './OrderDetail'

function OrderSteps() {
  const {pathname} = useLocation()

  const {order} = useCurrentOrder()

  const orderTypeIsActive = pathname === `/orders/view/${order.unique_id}/order-type`
  const petInformationIsActive = pathname === `/orders/view/${order.unique_id}/pet-information`
  const ownerInformationIsActive = pathname === `/orders/view/${order.unique_id}/owner-information`
  const memorialProductsIsActive = pathname === `/orders/view/${order.unique_id}/memorial-products`
  const reviewIsActive = pathname === `/orders/view/${order.unique_id}/review`

  return (
    <>
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-3 px-lg-6 px-xxl-10 py-10'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div
              className={clsx(
                'stepper-item',
                orderTypeIsActive && 'current',
                (petInformationIsActive ||
                  ownerInformationIsActive ||
                  memorialProductsIsActive ||
                  reviewIsActive) &&
                  'completed'
              )}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <Link className='stepper-label' to={`/orders/view/${order.unique_id}/order-type`}>
                  <h3 className='stepper-title'>Order Type</h3>
                  <div className='stepper-desc fw-semibold'>
                    {/* TODO: Dynamic based on order */}
                    {/*
                    {order.order_type
                      ? `${order.order_type.order_type_template.name}`
                      : 'Select type of cremation'}
                    */}
                    Select type of cremation
                  </div>
                </Link>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div
              className={clsx(
                'stepper-item',
                petInformationIsActive && 'current',
                (ownerInformationIsActive || memorialProductsIsActive || reviewIsActive) &&
                  'completed'
              )}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <Link
                  className='stepper-label'
                  to={`/orders/view/${order.unique_id}/pet-information`}
                >
                  <h3 className='stepper-title'>Pet Information</h3>
                  <div className='stepper-desc fw-semibold'>Name, type, breed, etc.</div>
                </Link>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div
              className={clsx(
                'stepper-item',
                ownerInformationIsActive && 'current',
                (memorialProductsIsActive || reviewIsActive) && 'completed'
              )}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <Link
                  className='stepper-label'
                  to={`/orders/view/${order.unique_id}/owner-information`}
                >
                  <h3 className='stepper-title'>Owner Information</h3>
                  <div className='stepper-desc fw-semibold'>Name, address, etc.</div>
                </Link>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div
              className={clsx(
                'stepper-item',
                memorialProductsIsActive && 'current',
                reviewIsActive && 'completed'
              )}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <Link
                  className='stepper-label'
                  to={`/orders/view/${order.unique_id}/memorial-products`}
                >
                  <h3 className='stepper-title'>Memorial Products</h3>
                  <div className='stepper-desc fw-semibold'>Add products to order</div>
                </Link>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 5*/}
            <div
              className={clsx('stepper-item', reviewIsActive && 'current')}
              data-kt-stepper-element='nav'
            >
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <Link className='stepper-label' to={`/orders/view/${order.unique_id}/review`}>
                  <h3 className='stepper-title'>Review</h3>
                  <div className='stepper-desc fw-semibold'>Final review</div>
                </Link>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 5*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}
    </>
  )
}

export default OrderSteps

import {useCallback, useEffect, useRef, useState} from 'react'
import ReconnectingWebSocket from 'reconnecting-websocket'
import {v4 as uuidv4} from 'uuid'

import {Order} from '../modules/apps/orders/orders-list/core/_models'
import {SimpleUserModel, useAuth} from '../modules/auth'

export type IAvailableStep =
  | 'order_type'
  | 'pet_information'
  | 'owner_information'
  | 'memorial_products'
  | 'review'

export class UserJoined {
  user: SimpleUserModel

  constructor(user: any) {
    this.user = new SimpleUserModel(user)
  }
}

export class UserLeft {
  user: SimpleUserModel

  constructor(user: any) {
    this.user = new SimpleUserModel(user)
  }
}

export class OrderStepActionInputChangedUpdate {
  step_type: IAvailableStep
  name: string
  value: any

  socket_identifier: string
  created_by_user: SimpleUserModel | null

  constructor(data: any, socket_identifier: string, user?: any) {
    this.step_type = data.step_type
    this.name = data.name
    this.value = data.value
    this.socket_identifier = socket_identifier
    this.created_by_user = user ? new SimpleUserModel(user) : null
  }
}

export function handleIncomingWebsocketMessage(
  e: any
): UserJoined | UserLeft | OrderStepActionInputChangedUpdate | undefined {
  if (!e.data) {
    console.warn('No data in websocket message')
    return
  }
  let data
  try {
    data = JSON.parse(e.data)
  } catch (error) {
    console.warn('Could not parse data: ', e.data)
    return
  }
  if (!data.type) {
    console.warn('No type in data: ', data)
    return
  }
  if (data.type === 'error') {
    console.warn('Error from websocket: ', data)
    return
  }
  if (data.type === 'simple_update') {
    // We have data.type, data.user, and data.payload
    // data.payload is the actual data we want to update
    // data.user is the user who made the change
    if (!data.payload) {
      console.warn('No payload in data: ', data)
      return
    }
    if (!data.user) {
      console.warn('No user in data: ', data)
      return
    }
    if (!data.socket_identifier) {
      console.warn('No socket_identifier in data: ', data)
      return
    }
    if (!data.payload.subtype) {
      console.warn('No subtype in data: ', data)
      return
    }
    if (data.payload.subtype === 'user_joined') {
      return new UserJoined(data.user)
    }
    if (data.payload.subtype === 'user_left') {
      return new UserLeft(data.user)
    }
    if (data.payload.subtype === 'step_actions.input_changed') {
      if (!data.payload.name) {
        console.warn('No name in data: ', data)
      }
      if (!data.payload.step_type) {
        console.warn('No step_type in data: ', data)
      }
      if (typeof data.payload.value !== 'string' && typeof data.payload.value !== 'object') {
        console.warn('Invalid value in data: ', data, ' received: ', typeof data.payload.value)
      }
      return new OrderStepActionInputChangedUpdate(data.payload, data.socket_identifier, data.user)
    }
  }
  console.warn(
    `Unknown subtype for simple_update (${data.payload.subtype || '(no subtype)'}): `,
    data
  )
  return undefined
}

function useOrderDetailWebsocket(order: Order | undefined) {
  /*
  We have a second websocket in case this scales differently than just the normal one.
  */

  const {auth, currentUser} = useAuth()
  const authToken = auth ? auth.api_token : null

  const connectedWebsocketClientId = useRef<string>(uuidv4())
  const [connectedWebsocket, setConnectedWebsocket] = useState<ReconnectingWebSocket | null>(null)

  const onSendWebsocketMessage = useCallback(
    (type: string, payload: any) => {
      if (!connectedWebsocket) {
        return
      }
      connectedWebsocket.send(JSON.stringify({type, payload}))
    },
    [connectedWebsocket]
  )

  const sendPageView = useCallback(
    (page: IAvailableStep) => {
      onSendWebsocketMessage('page_view', {page})
    },
    [onSendWebsocketMessage]
  )

  const sendInputFocused = useCallback(
    ({name, step}: {name: string; step: IAvailableStep}) => {
      onSendWebsocketMessage('step_actions.input_focused', {name, step})
    },
    [onSendWebsocketMessage]
  )

  const sendInputBlurred = useCallback(
    ({name, step}: {name: string; step: IAvailableStep}) => {
      onSendWebsocketMessage('step_actions.input_blurred', {name, step})
    },
    [onSendWebsocketMessage]
  )

  const sendCheckboxChanged = useCallback(
    ({name, value, step}: {step: IAvailableStep; name: string; value: boolean}) => {
      onSendWebsocketMessage('step_actions.checkbox_changed', {step, name, value})
    },
    [onSendWebsocketMessage]
  )

  const sendInputChanged = useCallback(
    ({step, name, value}: {step: IAvailableStep; name: string; value: string}) => {
      onSendWebsocketMessage('step_actions.input_changed', {step, name, value})
    },
    [onSendWebsocketMessage]
  )

  const sendObjectChanged = useCallback(
    ({step, name, value}: {step: IAvailableStep; name: string; value: any}) => {
      onSendWebsocketMessage('step_actions.object_changed', {step, name, value})
    },
    [onSendWebsocketMessage]
  )

  useEffect(() => {
    if (!authToken) {
      return
    }
    if (!order?.unique_id) {
      return
    }
    // Sets up the websocket connection for this card
    // clientId allows us to have multiple websockets open at once
    const webSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/orders/${order.unique_id}/view-order/?token=${authToken}&clientId=${connectedWebsocketClientId.current}`
    )
    setConnectedWebsocket(webSocket)
    return () => {
      webSocket.close()
    }
  }, [authToken, order?.unique_id])

  useEffect(() => {
    if (connectedWebsocket) {
      const handleMessage = (message: any) => {
        // First we parse the message. We use handleIncomingWebsocketMessage:
        const parsedMessage = handleIncomingWebsocketMessage(message)
        if (!parsedMessage || !currentUser) {
          // Warnings are logged up-stream of this function, so we can just
          // return here.
          return
        } else if (parsedMessage instanceof OrderStepActionInputChangedUpdate) {
          // Do nothing here (bind down-stream for now)
        } else {
          console.warn('Unhandled order detail message: ', parsedMessage)
        }
      }
      connectedWebsocket.addEventListener('message', handleMessage)
      return () => {
        if (connectedWebsocket) {
          connectedWebsocket.removeEventListener('message', handleMessage)
        }
      }
    }
  }, [connectedWebsocket, currentUser])

  return {
    connectedWebsocketClientId,
    connectedWebsocket,
    sendPageView,
    sendInputFocused,
    sendInputBlurred,
    sendCheckboxChanged,
    sendInputChanged,
    sendObjectChanged,
  }
}

export default useOrderDetailWebsocket

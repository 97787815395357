import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'

import {useCurrentOrder} from './OrderDetail'
import {OwnerInformation} from '../orders-list/core/_models'
import OrderBottomActions from '../components/OrderBottomActions'

import OwnerAddressAutocomplete, {GooglePlaceSelectOption} from './OwnerAddressAutocomplete'
import {useEffect, useState} from 'react'
import useWebsocketMessage from '../../../../hooks/useWebsocketMessage'
import useOrderDetailPartialLoader from '../../../../hooks/useOrderDetailPartialLoader'

export const ownerInformationSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email_adress: Yup.string().email(),
  phone_number: Yup.string(),
  manual_address: Yup.object().shape({
    owner_address_line_1: Yup.string(),
    owner_address_line_2: Yup.string(),
    owner_city: Yup.string(),
    owner_state_value: Yup.string(),
    owner_zip_code: Yup.string(),
  }),
})

const _ownerInformationToFormikValues = (ownerInformation: OwnerInformation): OwnerInformation => {
  return {
    owner_first_name: ownerInformation.owner_first_name || '',
    owner_last_name: ownerInformation.owner_last_name || '',
    owner_email_address: ownerInformation.owner_email_address || '',
    owner_phone_number: ownerInformation.owner_phone_number || '',
    google_place_address: ownerInformation.google_place_address || null,
    manual_address: {
      owner_address_line_1: ownerInformation.manual_address?.owner_address_line_1 || '',
      owner_address_line_2: ownerInformation.manual_address?.owner_address_line_2 || '',
      owner_city: ownerInformation.manual_address?.owner_city || '',
      owner_state_value: ownerInformation.manual_address?.owner_state_value || '',
      owner_zip_code: ownerInformation.manual_address?.owner_zip_code || '',
    },
    getSimpleName: ownerInformation.getSimpleName,
    getSingleLineDescription: ownerInformation.getSingleLineDescription,
  }
}

function OwnerForm() {
  const navigate = useNavigate()

  const {
    order,
    loadOrder,
    connectedWebsocketClientId,
    connectedWebsocket,
    sendInputChanged,
    sendObjectChanged,
  } = useCurrentOrder()

  const [enteringAddressManually, setEnteringAddressManually] = useState(
    order.owner_information?.manual_address?.owner_address_line_1 ||
      order.owner_information?.manual_address?.owner_address_line_2 ||
      order.owner_information?.manual_address?.owner_city ||
      order.owner_information?.manual_address?.owner_state_value ||
      order.owner_information?.manual_address?.owner_zip_code
      ? true
      : false
  )

  const [selectedAddress, setSelectedAddress] = useState<GooglePlaceSelectOption | null>(
    order.owner_information?.google_place_address || null
  )

  const formik = useFormik<OwnerInformation>({
    initialValues: _ownerInformationToFormikValues(
      order.owner_information || ({} as OwnerInformation)
    ),
    validationSchema: ownerInformationSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      console.log('submitting owner information form') // We use a websocket now, so nothing happens here
    },
  })

  const formError = formik.status

  const {loading: partialLoading, errorLoading: partialErrorLoading} = useOrderDetailPartialLoader({
    order,
    stepType: 'owner_information',
    onLoaded: (data: any) => {
      const updatedOwnerInformation = new OwnerInformation(data)
      formik.setValues(_ownerInformationToFormikValues(updatedOwnerInformation))
      setSelectedAddress(updatedOwnerInformation.google_place_address || null)
    },
  })

  useWebsocketMessage({
    connectedWebsocket,
    connectedWebsocketClientId,
    onReceivedStepMessage: (parsedMessage) => {
      if (parsedMessage.step_type === 'owner_information') {
        if (parsedMessage.name === 'owner_first_name') {
          formik.setFieldValue('owner_first_name', parsedMessage.value)
        } else if (parsedMessage.name === 'owner_last_name') {
          formik.setFieldValue('owner_last_name', parsedMessage.value)
        } else if (parsedMessage.name === 'owner_email_address') {
          formik.setFieldValue('owner_email_address', parsedMessage.value)
        } else if (parsedMessage.name === 'owner_phone_number') {
          formik.setFieldValue('owner_phone_number', parsedMessage.value)
        } else if (parsedMessage.name === 'manual_address.owner_address_line_1') {
          formik.setFieldValue('manual_address.owner_address_line_1', parsedMessage.value)
        } else if (parsedMessage.name === 'manual_address.owner_address_line_2') {
          formik.setFieldValue('manual_address.owner_address_line_2', parsedMessage.value)
        } else if (parsedMessage.name === 'manual_address.owner_city') {
          formik.setFieldValue('manual_address.owner_city', parsedMessage.value)
        } else if (parsedMessage.name === 'manual_address.owner_state_value') {
          formik.setFieldValue('manual_address.owner_state_value', parsedMessage.value)
        } else if (parsedMessage.name === 'manual_address.owner_zip_code') {
          formik.setFieldValue('manual_address.owner_zip_code', parsedMessage.value)
        } else if (parsedMessage.name === 'google_place_address') {
          setSelectedAddress(parsedMessage.value)
        } else {
          console.warn('Unknown `owner_information` name: ', parsedMessage.name)
        }
      }
    },
  })

  if (partialLoading) {
    return <></>
  }

  if (partialErrorLoading) {
    return <div className='alert alert-danger'>{partialErrorLoading}</div>
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='row'>
          <div className='col-6'>
            <div className='form-group'>
              <div className='d-flex mb-2 flex-column'>
                <label className='form-label'>Owner's First Name:</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder={`Enter the owner's first name...`}
                  required
                  autoComplete='off'
                  autoFocus={!order.owner_information?.owner_first_name}
                  {...formik.getFieldProps('owner_first_name')}
                  onChange={(event) => {
                    formik.handleChange(event)
                    const updatedValue = event.target.value
                    sendInputChanged({
                      step: 'owner_information',
                      name: 'owner_first_name',
                      value: updatedValue,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group'>
              <div className='d-flex mb-2 flex-column'>
                <label className='form-label'>Owner's Last Name:</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder={`Enter the owner's last name...`}
                  required
                  autoComplete='off'
                  {...formik.getFieldProps('owner_last_name')}
                  onChange={(event) => {
                    formik.handleChange(event)
                    const updatedValue = event.target.value
                    sendInputChanged({
                      step: 'owner_information',
                      name: 'owner_last_name',
                      value: updatedValue,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-6'>
            <div className='form-group'>
              <div className='d-flex mb-2 flex-column'>
                <label className='form-label'>Owner's Email Address:</label>
                <input
                  className='form-control'
                  type='email'
                  placeholder={`Enter the owner's email address...`}
                  required
                  autoComplete='off'
                  {...formik.getFieldProps('owner_email_address')}
                  onChange={(event) => {
                    formik.handleChange(event)
                    const updatedValue = event.target.value
                    sendInputChanged({
                      step: 'owner_information',
                      name: 'owner_email_address',
                      value: updatedValue,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group'>
              <div className='d-flex mb-2 flex-column'>
                <label className='form-label'>Owner's Phone Number:</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder={`Enter the owner's phone number...`}
                  required
                  autoComplete='off'
                  {...formik.getFieldProps('owner_phone_number')}
                  onChange={(event) => {
                    formik.handleChange(event)
                    const updatedValue = event.target.value
                    sendInputChanged({
                      step: 'owner_information',
                      name: 'owner_phone_number',
                      value: updatedValue,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-12'>
            <div className='form-group'>
              <div className='d-flex mb-2 flex-column'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='form-label m-0 p-0'>Owner's Address:</label>
                  <button
                    type='button'
                    className='btn btn-link btn-sm'
                    onClick={() => {
                      setEnteringAddressManually(!enteringAddressManually)
                    }}
                  >
                    {enteringAddressManually ? `Search for address` : `Enter address manually`}
                  </button>
                </div>
                {!enteringAddressManually ? (
                  <>
                    <OwnerAddressAutocomplete
                      selectedAddress={selectedAddress}
                      setSelectedAddress={(value: any) => {
                        setSelectedAddress(value)
                        sendObjectChanged({
                          step: 'owner_information',
                          name: 'google_place_address',
                          value,
                        })
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <label className='form-label'>Address Line 1: *</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps('manual_address.owner_address_line_1')}
                          onChange={(event) => {
                            formik.handleChange(event)
                            const updatedValue = event.target.value
                            sendInputChanged({
                              step: 'owner_information',
                              name: 'manual_address.owner_address_line_1',
                              value: updatedValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12'>
                        <label className='form-label'>Address Line 2:</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps('manual_address.owner_address_line_2')}
                          onChange={(event) => {
                            formik.handleChange(event)
                            const updatedValue = event.target.value
                            sendInputChanged({
                              step: 'owner_information',
                              name: 'manual_address.owner_address_line_2',
                              value: updatedValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-4'>
                        <label className='form-label'>City: *</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps('manual_address.owner_city')}
                          onChange={(event) => {
                            formik.handleChange(event)
                            const updatedValue = event.target.value
                            sendInputChanged({
                              step: 'owner_information',
                              name: 'manual_address.owner_city',
                              value: updatedValue,
                            })
                          }}
                        />
                      </div>
                      <div className='col-4'>
                        <label className='form-label'>State: *</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps('manual_address.owner_state_value')}
                          onChange={(event) => {
                            formik.handleChange(event)
                            const updatedValue = event.target.value
                            sendInputChanged({
                              step: 'owner_information',
                              name: 'manual_address.owner_state_value',
                              value: updatedValue,
                            })
                          }}
                        />
                      </div>
                      <div className='col-4'>
                        <label className='form-label'>Zip Code: *</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps('manual_address.owner_zip_code')}
                          onChange={(event) => {
                            formik.handleChange(event)
                            const updatedValue = event.target.value
                            sendInputChanged({
                              step: 'owner_information',
                              name: 'manual_address.owner_zip_code',
                              value: updatedValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <OrderBottomActions
          hideBack={false}
          onBack={() => {
            navigate(`/orders/view/${order.unique_id}/pet-information`)
          }}
          onContinue={() => {
            navigate(`/orders/view/${order.unique_id}/memorial-products`)
          }}
        />
      </form>
    </div>
  )
}

export default OwnerForm

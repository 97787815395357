export interface BaseDogBreed {
  name: string;
  url?: string;
  isAkc?: boolean;
}

const akcBreeds = require("./akc_breeds.json") as BaseDogBreed[];
const designerBreeds = require("./designer_breeds.json") as BaseDogBreed[];

// Combine the array and sort by name

const dogBreeds = [
  ...akcBreeds.map((x) => {
    x.isAkc = true;
    return x;
  }),
  ...designerBreeds,
].sort((a, b) => a.name.localeCompare(b.name));

export default dogBreeds;

type Props = {
  value: string
  label: string
}

const DashboardCountWidget = ({value, label}: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10`}
  >
    <div className='card-body d-flex justify-content-center align-items-center flex-column'>
      <span className='fs-2hx fw-bold me-2 lh-1 ls-n2'>{value}</span>
      <span className='opacity-75 pt-1 fw-semibold fs-6'>{label}</span>
    </div>
  </div>
)
export default DashboardCountWidget

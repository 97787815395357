import React, {useState} from 'react'
import clsx from 'clsx'
import {ButtonGroup} from 'react-bootstrap'

const PetTypeSelector = ({
  typeOfBreed,
  setTypeOfBreed,
}: {
  typeOfBreed: 'cat' | 'dog' | 'other' | null
  setTypeOfBreed: (typeOfBreed: 'cat' | 'dog' | 'other' | null) => void
}) => {
  return (
    <ButtonGroup className='d-flex'>
      <button
        type='button'
        className={clsx('btn', typeOfBreed === 'dog' ? 'btn-primary' : 'btn-light-primary')}
        onClick={() => setTypeOfBreed('dog')}
      >
        Dog
      </button>
      <button
        type='button'
        className={clsx('btn', typeOfBreed === 'cat' ? 'btn-primary' : 'btn-light-primary')}
        onClick={() => setTypeOfBreed('cat')}
      >
        Cat
      </button>
      <button
        type='button'
        className={clsx('btn', typeOfBreed === 'other' ? 'btn-primary' : 'btn-light-primary')}
        onClick={() => setTypeOfBreed('other')}
      >
        Other
      </button>
    </ButtonGroup>
  )
}

export default PetTypeSelector

import clsx from 'clsx'
import React, {useState} from 'react'
import {ButtonGroup, Button} from 'react-bootstrap'

const GenderSelector = ({
  typeOfGender,
  setTypeOfGender,
}: {
  typeOfGender: 'male' | 'female' | null
  setTypeOfGender: (typeOfBreed: 'male' | 'female' | null) => void
}) => {
  return (
    <ButtonGroup className='d-flex'>
      <button
        type='button'
        className={clsx('btn', typeOfGender === 'male' ? 'btn-primary' : 'btn-light-primary')}
        onClick={() => setTypeOfGender('male')}
      >
        Male
      </button>
      <button
        type='button'
        className={clsx('btn', typeOfGender === 'female' ? 'btn-primary' : 'btn-light-primary')}
        onClick={() => setTypeOfGender('female')}
      >
        Female
      </button>
    </ButtonGroup>
  )
}

export default GenderSelector
